import { Flex } from "antd";
import React from "react";

import Table from "../../components/Table";
import { useGetAvaliableSuppliersQuery } from "../../services/user/api";
import { columns } from "./mock";

export const SupplierList: React.FC = () => {
	const { data: suppliers, isLoading } = useGetAvaliableSuppliersQuery();
	return (
		<Flex
			className="w-full"
			vertical
			justify="center"
			align="center">
			<Flex
				className="w-3/4 pt-20"
				vertical>
				<p className="text-lg font-bold">Список постачальників</p>
				<Table
					loading={isLoading}
					columns={columns}
					dataSource={(suppliers?.results || []).map((supplier, idx) => ({ ...supplier, id: idx }))}
				/>
			</Flex>
		</Flex>
	);
};
