import { Flex, Image } from "antd";

import Section from "../../../components/Section";
import Slider from "../../../components/Slider";
import partners from "./mock";

export const Partners: React.FC = () => {
	return (
		<Section className="bg-secondary/10">
			<Flex
				vertical
				align="center">
				<Image
					className="mb-[18px] w-[350px]"
					preview={false}
					src="/images/home/partners/Union.svg"
					alt="union"
				/>
				<h2 className="mb-11 mt-0 font-russo text-[64px] font-normal uppercase text-white">
					<span className="text-text">Партнери</span> проекту
				</h2>
				<Slider partners={partners} />
			</Flex>
		</Section>
	);
};
