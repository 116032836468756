import { UserRoles } from "../../constants/userRoles";

export const getProfileColumns = (role: UserRoles) => {
	return [
		{
			title: "Збірка",
			dataIndex: "name",
			key: "name"
		},
		{
			title: "Статус",
			dataIndex: "status",
			key: "status"
		},
		{
			title: role === UserRoles.CONSUMER ? "Виконавець" : "Замовник",
			dataIndex: role === UserRoles.CONSUMER ? "craftsmanName" : "consumerName",
			key: role === UserRoles.CONSUMER ? "craftsmanName" : "consumerName"
		},
		{
			title: "Серійний номер",
			dataIndex: "slugId",
			key: "slugId"
		}
	];
};
