import { Flex } from "antd";
import React from "react";

import Form from "./Form";
import HowToBecomeSupplier from "./HowToBecomeSupplier";

export const Supplier: React.FC = (): JSX.Element => {
	return (
		<Flex
			vertical
			className="bg-dark">
			<HowToBecomeSupplier />;
			<Form />
		</Flex>
	);
};
