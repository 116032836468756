import { Avatar, Button, Flex, Input } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { UserRoles } from "../../../constants/userRoles";
import { Routes } from "../../../router/routes";
import { useUpdateProfileMutation } from "../../../services/user/api";
import { profileSelector } from "../../../store/user/selectors";

const UserCard: React.FC = (): JSX.Element => {
	const profile = useSelector(profileSelector);
	const navigate = useNavigate();
	const [updateProfile] = useUpdateProfileMutation();
	const [isEditField, setEditField] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>(profile?.name || "");

	if (!profile) return <></>;

	const { role, uuid, consumer, investor, craftsman, created } = profile;

	const userCardsData: { [key: string]: { title: string; value?: string }[] } = {
		[UserRoles.INVESTOR]: [
			{ title: "Коштів перераховано у гривнях", value: investor?.donatesAmount }
		],
		[UserRoles.CONSUMER]: [
			{ title: "Очікуємо дронів", value: consumer?.statisticDronesInWork },
			{ title: "Дронів отримано", value: consumer?.statisticDronesDone }
		],
		[UserRoles.CRAFTSMAN]: [
			{ title: "Дрони в роботі", value: craftsman?.statisticDronesInWork },
			{ title: "Дронів зроблено", value: craftsman?.statisticDronesDone }
		]
	};

	return (
		<>
			<Flex
				justify="space-between"
				className="w-3/4 pt-10">
				<Avatar
					size={158}
					src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
				/>
				<Flex vertical>
					<p className="mb-1 text-xl font-bold">Персональний ID</p>
					<span className="mb-2 block">{uuid}</span>
					<p className="mb-1 text-xl font-bold">
						{role === UserRoles.CRAFTSMAN ? "Позивний" : "Імʼя"}
					</p>
					{!isEditField ? (
						<span className="mb-2 block">{inputValue}</span>
					) : (
						<Input
							placeholder="Enter your username"
							value={inputValue}
							onChange={(e) => {
								setInputValue(e.target.value);
							}}
						/>
					)}
					<p className="mb-1 text-xl font-bold">Створений</p>
					<span className="mb-2 block">{created && new Date(created).toLocaleDateString()}</span>
					<Flex gap={4}>
						<Button
							disabled={isEditField}
							type="default"
							onClick={() => {
								setEditField(true);
							}}>
							Редагувати
						</Button>
						<Button
							disabled={!isEditField}
							type="default"
							onClick={() => {
								setEditField(false);
								updateProfile({ ...profile, name: inputValue });
							}}>
							Зберегти
						</Button>
					</Flex>
				</Flex>
				<Flex vertical>
					{userCardsData[role].map(({ title, value }) => (
						<Flex
							key={title}
							vertical
							align="center"
							className="border-sky-500 mb-4 w-64 border-2 border-solid">
							<p className="mb-2 text-2xl font-bold">{title}</p>
							<span className="text-xl">{value}</span>
						</Flex>
					))}
				</Flex>
				{role === UserRoles.CONSUMER && (
					<Button
						type="default"
						onClick={() => {
							navigate(Routes.DRONE_CREATION_REQUEST);
						}}>
						Замовити дрони
					</Button>
				)}
			</Flex>
		</>
	);
};
export default UserCard;
