import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "../components/Layout";
import { routes } from "./routes";

export const AppRouter = () => {
	return (
		<BrowserRouter>
			<Routes>
				{routes.map(({ authOnly, element, ...route }) => (
					<Route
						{...route}
						element={authOnly ? <Layout>{element}</Layout> : element}
					/>
				))}
			</Routes>
		</BrowserRouter>
	);
};
