import { createSlice } from "@reduxjs/toolkit";

import { getDronesList } from "../../services/drones/api.ts";
import type { DronesSchema } from "./types.ts";

const initialState: DronesSchema = {
	list: []
};

export const dronesSlice = createSlice({
	name: "drones",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(getDronesList.matchFulfilled, (state, { payload }) => {
			state.list = payload.results;
		});
	}
});

export const { reducer: dronesReducer } = dronesSlice;
