import type { RouteProps } from "react-router-dom";

import DroneCreationRequest from "../pages/DroneCreationRequest";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import PersonalProfile from "../pages/PersonalProfile";
import Supplier from "../pages/Supplier";
import SupplierList from "./../pages/SupplierList";

export enum Routes {
	HOME = "/",
	LOGIN = "/login",
	NOT_FOUND = "*",
	PERSONAL_PROFILE = "/personal-profile",
	DRONE_CREATION_REQUEST = "/drone-creation-request",
	SUPPLIER_LIST = "/supplier-list",
	SUPPLIER = "/supplier"
}

type IRoute = {
	authOnly?: boolean;
} & RouteProps;

export const routes: IRoute[] = [
	{
		path: Routes.LOGIN,
		element: <Home />
	},
	{
		index: true,
		path: Routes.HOME,
		element: <Home />,
		authOnly: true
	},
	{
		path: Routes.NOT_FOUND,
		element: <NotFound />
	},
	{
		path: Routes.PERSONAL_PROFILE,
		element: <PersonalProfile />,
		authOnly: true
	},
	{
		path: Routes.DRONE_CREATION_REQUEST,
		element: <DroneCreationRequest />,
		authOnly: true
	},
	{
		path: Routes.SUPPLIER_LIST,
		element: <SupplierList />,
		authOnly: true
	},
	{
		path: Routes.SUPPLIER,
		element: <Supplier />,
		authOnly: true
	}
];
