import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { AppRouter } from "./router/AppRouter";
import { persistor, store } from "./store/store";
import { config } from "./styles/antConfig";

function App() {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<ConfigProvider theme={config}>
					<AppRouter />
				</ConfigProvider>
			</PersistGate>
		</Provider>
	);
}

export default App;
