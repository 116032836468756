import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { rtkApi } from "../services/rtkApi.tsx";
import { dronesReducer } from "./drones/slice.ts";
import { userReducer } from "./user/slice.ts";

const userPersistConfig = {
	key: "auth",
	blacklist: ["profile"],
	storage
};

const reducers = combineReducers({
	[rtkApi.reducerPath]: rtkApi.reducer,
	user: persistReducer(userPersistConfig, userReducer),
	drones: dronesReducer
});

export const store = configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(rtkApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
