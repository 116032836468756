import { Flex, Layout } from "antd";

import Footer from "../Footer";
import Header from "../Header";

interface IPageLayoutProps {
	children: React.ReactNode;
}

export const PageLayout = ({ children }: IPageLayoutProps) => {
	return (
		<Layout className="min-h-screen justify-between">
			<Flex
				className="h-full"
				vertical>
				<Header />
				{children}
			</Flex>
			<Footer />
		</Layout>
	);
};
