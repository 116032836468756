import { Button, Col, Flex, Row } from "antd";
import React, { useState } from "react";

import Input from "../../../components/Input";
import Section from "../../../components/Section";

export const Form: React.FC = (): JSX.Element => {
	const [formData, setFormData] = useState({
		name: "",
		type: "",
		email: "",
		phone: "",
		storeLink: ""
	});

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};
	return (
		<Section
			background="/images/home/bg-form.png"
			className="h-full">
			<Flex
				vertical
				justify="center"
				align="center"
				className="pt-4">
				<Flex
					vertical
					align="center">
					<img src="/images/home/yellow-title-line.png" />
					<h2 className="mb-[20px] mt-[15px] text-[64px] font-normal leading-tight text-primary">
						СТАТИ <span className="text-white">ПОСТАЧАЛЬНИКОМ</span>
					</h2>
					<p className="m-0 text-center text-[24px] leading-tight text-white">
						МИ ЦІНУЄМО КОЖНОГО НАШОГО ПАРТНЕРА ТА ПРАГНЕМО ДО ДОВГОСТРОКОВОЇ ТА ВЗАЄМОВИГІДНОЇ
						СПІВПРАЦІ. ДОЛУЧАЙТЕСЬ ДО НАС, ЩОБ РАЗОМ РОБИТИ ВНЕСОК У РОЗВИТОК ІННОВАЦІЙНИХ
						ТЕХНОЛОГІЙ ТА ПІДТРИМУВАТИ ОБОРОНОЗДАТНІСТЬ КРАЇНИ.
					</p>
				</Flex>
				<form className="mt-[75px] w-[900px]">
					<Row
						gutter={[25, 24]}
						className="mb-[25px] font-yanone">
						<Col span={8}>
							<Input
								placeholder="ІМ'Я"
								value={formData.name}
								onChange={handleInputChange}
								name="name"
							/>
						</Col>
						<Col span={8}>
							<Input
								placeholder="ТИП ОСОБИ (ФІЗИЧНА ЧИ ЮРИДИЧНА)"
								value={formData.type}
								onChange={handleInputChange}
								name="type"
							/>
						</Col>
						<Col span={8}>
							<Input
								placeholder="ЕЛЕКТРОННА ПОШТА"
								value={formData.email}
								onChange={handleInputChange}
								name="email"
							/>
						</Col>
					</Row>
					<Row gutter={[25, 24]}>
						<Col span={8}>
							<Input
								placeholder="ТЕЛЕФОН"
								value={formData.phone}
								onChange={handleInputChange}
								name="phone"
							/>
						</Col>
						<Col span={16}>
							<Input
								placeholder="ПОСИЛАННЯ НА ВАШ МАГАЗИН АБО КАТАЛОГ ЗАПЧАСТИН"
								value={formData.storeLink}
								onChange={handleInputChange}
								name="storeLink"
							/>
						</Col>
					</Row>
				</form>
				<Button
					type="primary"
					className="mt-[25px] h-[48px] w-[208px] rounded-none bg-primary font-yanone text-[24px] font-bold leading-none text-dark">
					ЗАЛИШИТИ ЗАЯВКУ
				</Button>
			</Flex>
		</Section>
	);
};
