import { UserRoles } from "../../../constants/userRoles";

export const textData: {
	[key: string]: {
		title: string;
		content: {
			part1: string;
			part2: string;
		};
	};
} = {
	[UserRoles.CONSUMER]: {
		title: "ЗАМОВНИК ДРОНІВ",
		content: {
			part1:
				"Замовники дронів — юридичні особи, зокрема підрозділи Збройних Сил України, військові частини та бригади, що діють відповідно до законодавства України та мають потребу в спеціалізованих дронах для виконання військових завдань на фронті, включаючи дрони FPV для розвідки, спостереження та інших спеціалізованих місій. Замовники авторизуються на платформі через мобільний додаток Порталу (Дія) під роллю «Замовник дронів».",
			part2: `Після реєстрації замовлення, воно автоматично передається виробнику дронів, який бере на себе відповідальність за виготовлення, збірку та попереднє тестування дрона відповідно до вибраного типу.\n\nДрони надаються замовникам безкоштовно, оскільки витрати на їх розробку, виготовлення та доставку покриваються за рахунок коштів інвесторів, які підтримують ініціативу з метою сприяння обороноздатності країни.`
		}
	},
	[UserRoles.CRAFTSMAN]: {
		title: "ВИРОБНИК ДРОНІВ",
		content: {
			part1:
				"Виробники дронів — юридичні особи, компанії або організації, зареєстровані на території України, які спеціалізуються на розробці, виготовленні та тестуванні дронів для військових та цивільних потреб. Виробники мають необхідні знання, досвід та технологічні можливості для створення дронів, які відповідають специфікаціям та вимогам замовників.\n\n Вони авторизуються на платформі через мобільний додаток Порталу (Дія) під роллю «Виробник дронів»,",
			part2: `де приймають замовлення від замовників, у тому числі від військових підрозділів, для виробництва дронів на основі вибраних типів та призначення.\n\n Після отримання замовлення виробник координує процес виготовлення, збірки та попереднього тестування дрона, забезпечуючи, щоб кінцевий продукт відповідав всім технічним та оперативним вимогам.\n\nДрони, виготовлені виробниками, надаються замовникам безкоштовно, фінансування проектів здійснюється за рахунок коштів інвесторів, які підтримують ініціативи з метою сприяння обороноздатності України.`
		}
	},
	[UserRoles.INVESTOR]: {
		title: "ІНВЕСТОР",
		content: {
			part1:
				"Інвестори — це фізичні та юридичні особи, які підтримують оборону України, фінансуючи виробництво дронів для військових цілей. Вони реєструються на платформі через мобільний додаток Порталу (Дія) під роллю «Інвестор», де перераховують кошти на спеціально відведений рахунок платформи. Ці кошти платформа використовує для закупівлі комплектуючих, необхідних для створення дронів, які згодом",
			part2:
				"слугуватимуть Збройним Силам України та іншим військовим формуванням у виконанні розвідувальних, спостережних та бойових місій.\n\nКрім того, інвестори отримують звіти про використання коштів, що забезпечує прозорість фінансування. Це включає детальні звіти про придбання комплектуючих, етапи виробництва дронів, а також інформацію про те, яким чином їх внески сприяли підтримці та розвитку військових здібностей України. Така звітність дозволяє інвесторам бачити результати своєї підтримки."
		}
	}
};
