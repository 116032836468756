import { Flex } from "antd";
import clsx from "clsx";

interface ISectionProps {
	background?: string;
	overlay?: string;
	className?: string;
	children: React.ReactNode;
}

export const Section = ({ children, background, overlay, className }: ISectionProps) => {
	return (
		<Flex className={clsx("relative z-0 block w-full", className)}>
			{background && (
				<img
					className="h-full w-full object-cover"
					src={background}
					alt="section-background"
				/>
			)}
			{overlay && (
				<img
					className={clsx("absolute left-0 top-0 h-full w-full object-cover", {
						["relative z-[1]"]: !background
					})}
					src={overlay}
					alt="section-overlay"
				/>
			)}
			<Flex
				vertical
				className={clsx("h-full w-full p-16", {
					["relative h-fit"]: !background && !overlay,
					["absolute left-0 top-0"]: background || overlay
				})}>
				{children}
			</Flex>
		</Flex>
	);
};
