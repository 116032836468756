import { Flex } from "antd";

import Hero from "./Hero/index";
import HowltWorks from "./HowItWorks";
import JoinToProject from "./JoinToProject";
import Partners from "./Partners/index";
import Roles from "./Roles";

export const Home = () => {
	return (
		<Flex
			vertical
			className="bg-dark">
			<Hero />
			<HowltWorks />
			<Roles />
			<JoinToProject />
			<Partners />
		</Flex>
	);
};
