export const BASE_URL = "http://176.223.128.126:8000/api/v1/";

export const API_ROUTES = {
	user: {
		login: "user/login/",
		profile: "user/profile/",
		suppliers: "user/suppliers/"
	},
	drone: "drone/",
	order: { create: "/order/create/" }
};
