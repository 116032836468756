import { API_ROUTES } from "../../constants/api";
import { rtkApi } from "../rtkApi";
import type { OrderRequest } from "./types";

export const ordersApi = rtkApi.injectEndpoints({
	endpoints: (build) => ({
		createOrder: build.mutation<void, OrderRequest>({
			query: (data) => ({
				url: API_ROUTES.order.create,
				method: "POST",
				body: data
			})
		})
	})
});

export const {
	endpoints: { createOrder },
	useCreateOrderMutation
} = ordersApi;
