import { Button, Flex, Typography } from "antd";
import React, { useState } from "react";

import Table from "../../components/Table";
import { useGetDronesListQuery } from "../../services/drones/api";
import { useCreateOrderMutation } from "../../services/orders/api";
import type { Goods } from "../../types/order";
import { getDroneColumns } from "./mock";

const { Title } = Typography;

export interface SelectedDrones extends Goods {
	selected: boolean;
}

export const DroneCreationRequest: React.FC = (): JSX.Element => {
	const [selectedDrones, setSelectedDrones] = useState<SelectedDrones[]>([]);
	const { data: drones, isLoading } = useGetDronesListQuery();
	const [createOrder] = useCreateOrderMutation();

	if (isLoading) return <></>;

	const handleCreateOrder = async () => {
		const dronesToOrder = selectedDrones.filter(({ selected, count }) => selected && count > 0);
		dronesToOrder.length && (await createOrder({ goods: dronesToOrder }));
	};

	return (
		<Flex
			vertical
			className="p-20"
			gap={32}>
			<Flex vertical>
				<Title level={4}>Доступні моделі:</Title>
				<Table
					size="middle"
					loading={isLoading}
					dataSource={drones?.results || []}
					columns={getDroneColumns({ selectedDrones, setSelectedDrones })}
				/>
			</Flex>
			<Button
				className="w-48 self-center"
				onClick={handleCreateOrder}>
				Замовити
			</Button>
		</Flex>
	);
};
