import { API_ROUTES } from "../../constants/api";
import type { Profile, User } from "../../types/user";
import { rtkApi } from "../rtkApi";
import type { AvaliableSupplierResponse, LoginPequest, LoginResponse } from "./types";

export const userApi = rtkApi.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation<LoginResponse, LoginPequest>({
			query: (data) => ({
				url: API_ROUTES.user.login,
				method: "POST",
				body: data
			})
		}),
		getProfile: build.query<Profile, void>({
			query: () => ({
				url: API_ROUTES.user.profile
			})
		}),
		updateProfile: build.mutation<Profile, Partial<User>>({
			query: (data) => ({
				url: API_ROUTES.user.profile,
				method: "PUT",
				body: data
			})
		}),
		getAvaliableSuppliers: build.query<AvaliableSupplierResponse, void>({
			query: () => ({
				url: API_ROUTES.user.suppliers
			})
		})
	})
});

export const {
	endpoints: { login, getProfile },
	useLoginMutation,
	useGetProfileQuery,
	useLazyGetProfileQuery,
	useUpdateProfileMutation,
	useGetAvaliableSuppliersQuery
} = userApi;
