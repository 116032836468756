import type { TableColumnsType, TableProps } from "antd";
import { Table } from "antd";

type IdDataType<DataType> = {
	id: string | number;
} & DataType;

type TableComponentProps<DataType> = TableProps & {
	dataSource: IdDataType<DataType>[];
	columns: TableColumnsType<DataType>;
};

export const TableComponent = <DataType,>({
	pagination = false,
	columns,
	...props
}: TableComponentProps<DataType>) => {
	return (
		<Table
			{...props}
			bordered
			columns={columns.map((column) => ({ align: "center", ...column }))}
			className="text-center align-middle"
			pagination={pagination}
		/>
	);
};
