import { Button, Flex } from "antd";
import React from "react";

import Section from "../../../components/Section";

// TODO: Uncomment when cards with amount should be visible
// const mockData = {
// 	dronesInWork: 400,
// 	dronesMade: 1250
// };

export const Hero: React.FC = () => {
	return (
		<Section
			className="h-full"
			background="/images/home/hero-background.png"
			overlay="/images/home/hero-overlay.png">
			<Flex
				vertical
				justify="space-around"
				className="h-[90%]">
				<Flex justify="space-between">
					<p className="tracking-4 w-1/2 text-3xl font-normal uppercase text-white">
						"<span className="text-text">росія зникне</span>, коли зійде
						<span className="text-primary">
							<br />
							Українське сонце
						</span>
						"
						<br />
						<span className="pl-[150px]">- Джохар Дудаєв</span>
					</p>
					<img
						src="/images/home/hero-drone.png"
						alt="drone"
						className="absolute right-0 top-[10%] w-1/2"
					/>
				</Flex>
				<Flex
					className="h-32 w-full"
					// TODO: Uncomment when cards with amount should be visible
					// justify="space-between"
					justify="flex-end"
					gap={60}>
					{/* // TODO: Uncomment when cards with amount should be visible */}
					{/* <Flex
						justify="space-evenly"
						gap={100}>
						<Flex
							className="z-1 relative h-[125px] w-[335px] bg-[url('/public/images/home/hero-drones-work.png')] bg-cover brightness-50 filter "
							align="center"
							justify="center"
							gap={16}>
							<p className="z-20 m-0 w-min border-0 border-r-4 border-solid border-primary  pr-5 text-center text-7xl text-white">
								{mockData.dronesInWork}
							</p>
							<p className="z-20 w-min min-w-28 font-yanone text-4xl text-primary">
								ДРОНИ В РОБОТІ
							</p>
							<img
								src="/images/home/yellow-left.png"
								className="absolute left-0 top-0 z-10"
							/>
							<img
								src="/images/home/yellow-right.png"
								className="absolute bottom-0 right-0 z-10"
							/>
						</Flex>
						<Flex
							className="z-1 relative h-[125px] w-[335px] bg-[url('/public/images/home/hero-drones-made.png')] bg-cover brightness-50 filter "
							align="center"
							justify="center"
							gap={16}>
							<p className="z-20 m-0 border-0 border-r-4 border-solid border-secondary pr-5 text-center text-7xl text-white">
								{mockData.dronesMade}
							</p>
							<p className="z-20 w-min font-yanone text-4xl text-white">ДРОНИ ЗРОБЛЕНІ</p>
							<img
								src="/images/home/blue-left.png"
								className="absolute left-0 top-0 z-10"
							/>
							<img
								src="/images/home/blue-right.png"
								className="absolute bottom-0 right-0 z-10"
							/>
						</Flex>
					</Flex> */}
					<Flex
						vertical
						justify="center"
						gap={10}
						className="relative w-[30%]">
						<Button className="relative flex h-12 items-center justify-evenly border-none bg-primary pt-2 font-yanone text-3xl font-semibold text-dark">
							<img
								src="/images/home/icon-drone.png"
								alt="drone-icon"
								className="abosute left-0 top-0 h-[30px] w-[54px]"
							/>
							<p>ПРИЄДНАТИСЯ ДО ПРОЄКТУ</p>
						</Button>
						<p className="absolute -bottom-4 font-yanone text-xl text-text">наближаймо перемогу</p>
					</Flex>
				</Flex>
			</Flex>
		</Section>
	);
};
