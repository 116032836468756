import { API_ROUTES } from "../../constants/api";
import { rtkApi } from "../rtkApi";
import type { DronesResponse } from "./types";

export const dronesApi = rtkApi.injectEndpoints({
	endpoints: (build) => ({
		getDronesList: build.query<DronesResponse, void>({
			query: () => ({
				url: API_ROUTES.drone
			})
		})
	})
});

export const {
	endpoints: { getDronesList },
	useGetDronesListQuery
} = dronesApi;
