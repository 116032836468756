import type { ThemeConfig } from "antd";

export const config: ThemeConfig = {
	token: {
		//text colors:
		colorTextHeading: "#EAC615",
		colorTextSecondary: "#4D15EA",
		colorTextBase: "#ACA4A4",
		colorText: "#ACA4A4",

		fontFamily: "Russo, sans-serif"
	},
	components: {
		Button: {
			defaultBg: "transparent",
			defaultHoverBg: "transparent",
			defaultColor: "#4D15EA",
			defaultBorderColor: "#4D15EA",
			defaultHoverColor: "#EAC615",
			defaultHoverBorderColor: "#EAC615"
		},
		Menu: {
			itemColor: "#ACA4A4",
			horizontalItemSelectedColor: "#EAC615",
			itemHoverBg: "#EAC615",
			itemHoverColor: "#EAC615"
		},
		Typography: {
			titleMarginTop: 0,
			titleMarginBottom: "4px"
		},
		Modal: {
			colorBgMask: "rgba(0, 0, 0, 0.5)"
		},
		Table: {
			headerBg: "#598a4c",
			headerColor: "#FFFFFF",
			borderColor: "#000000",
			headerBorderRadius: 0
		},
		Input: {
			colorBgContainer: "rgba(172, 164, 164, 0.15)",
			colorBorder: "#ACA4A4",
			hoverBorderColor: "#ACA4A4",
			activeBorderColor: "#ACA4A4",
			borderRadius: 0,
			borderRadiusOuter: 0,
			fontSize: 24,
			paddingBlock: 4,
			activeShadow: "none",
			colorTextPlaceholder: "#ACA4A4",
			colorText: "#ACA4A4"
		}
	}
};
