import { createSlice } from "@reduxjs/toolkit";

import { getProfile, login } from "../../services/user/api.ts";
import type { UserSchema } from "./types.ts";

const initialState: UserSchema = {
	accessToken: "",
	refreshToken: "",
	profile: null
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		cleanAuthData: () => initialState
	},
	extraReducers: (builder) => {
		builder.addMatcher(login.matchFulfilled, (state, { payload }) => {
			state.accessToken = payload.access;
			state.refreshToken = payload.refresh;
			state.profile = null;
		});
		builder.addMatcher(getProfile.matchFulfilled, (state, { payload }) => {
			state.profile = payload;
		});
	}
});

export const { reducer: userReducer } = userSlice;
