export const data = [
	{
		key: "1",
		title: "Визначись із роллю",
		list: ["Замовник дронів", "Виробник дронів", "Інвестор"]
	},
	{
		key: "2",
		title: "Авторизуйся",
		list: ["Пройди авторизацію за допомогою Дія.Підпису"]
	},
	{
		key: "3",
		title: "Виконай дію",
		list: ["Зроби замовлення дронів", "Збери дрон", "Перерахуй кошти на дрони"]
	},
	{
		key: "4",
		title: "Отримати результат",
		list: ["Отримай дрони", "Відправ дрони", "Наближай перемогу"]
	}
];
