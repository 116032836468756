import { RightOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import clsx from "clsx";
import React, { useState } from "react";

import { UserRoles } from "../../../constants/userRoles";
import { Section } from "./../../../components/Section/Section";
import { textData } from "./roleConfig";

export const Roles: React.FC = () => {
	const [currentRole, setCurrentRole] = useState(UserRoles.CONSUMER);
	const handleButtonClick = (buttonName: UserRoles) => {
		setCurrentRole(buttonName);
	};

	const { title, content } = textData[currentRole];

	return (
		<Section
			background="/images/home/bg-roles.png"
			className="top-[-105px]">
			<img
				src="/images/home/line-roles.png"
				alt="line"
				className="mx-auto my-[20px] w-[350px]"
			/>
			<h2 className="m-0 mb-[65px] text-center text-[64px] font-normal text-text">
				РОЛІ В <span className="text-white">ПРОЄКТІ</span>
			</h2>
			<Flex gap="50px">
				<Flex
					vertical
					gap="50px"
					align="start"
					className="w-[220px]">
					{Object.values(UserRoles).map((role) => (
						<Button
							key={role}
							type="link"
							className={clsx("flex w-60 items-center justify-items-center p-0 text-xl", {
								["text-secondary"]: currentRole === role,
								["text-text"]: currentRole !== role
							})}
							onClick={() => {
								handleButtonClick(role);
							}}>
							<>
								{role === UserRoles.CONSUMER && <p className="m-0 font-bold">ЗАМОВНИК ДРОНІВ</p>}
								{role === UserRoles.CRAFTSMAN && <p className="m-0 font-bold">ВИРОБНИК ДРОНІВ</p>}
								{role === UserRoles.INVESTOR && <p className="m-0 font-bold">ІНВЕСТОР</p>}
							</>
							{currentRole === role && <RightOutlined style={{ fontWeight: "bold" }} />}
						</Button>
					))}
				</Flex>
				<Flex>
					<div className="h-[326px] w-full border-solid border-secondary bg-secondary bg-opacity-10 px-[28px] pt-[26px]">
						<Flex gap="30px">
							<Flex
								vertical
								className="w-[50%]">
								<h4 className="text-normal m-0 font-russo text-xl text-white">{title}</h4>
								<p className="mb-0 mt-[31px] whitespace-pre-wrap font-yanone text-xl leading-6 text-text">
									{content.part1}
								</p>
							</Flex>
							<p className="m-0 mb-0 w-[50%] whitespace-pre-wrap font-yanone text-xl leading-6 text-text">
								{content.part2}
							</p>
						</Flex>
					</div>
				</Flex>
			</Flex>
		</Section>
	);
};
