import type { ColumnsType } from "antd/es/table";

import type { AvaliableSupplier } from "../../types/user";

export const columns: ColumnsType<AvaliableSupplier> = [
	{
		title: "Імя",
		dataIndex: "name",
		key: "name"
	},
	{
		title: "Тип",
		dataIndex: "supplierType",
		key: "supplierType"
	},
	{
		title: "Телефон",
		dataIndex: "phoneNumber",
		key: "phoneNumber"
	},
	{
		title: "Електронна пошта",
		dataIndex: "email",
		key: "email"
	},
	{
		title: "Магазин",
		dataIndex: "websiteUrl",
		key: "websiteUrl"
	}
];
