import { Layout } from "antd";
import React from "react";

const { Footer } = Layout;

export const FooterComponent: React.FC = () => {
	return (
		<Footer className="flex h-[35px] w-full items-center justify-center self-end bg-[#101012] p-0">
			<h4 className="m-0 text-text">СОНЦЕ РУЇНИ 2024</h4>
		</Footer>
	);
};
