import { Flex } from "antd";
import { Link } from "react-router-dom";

export const NotFound = () => {
	return (
		<>
			404
			<Flex vertical>
				<Link
					to={"/home"}
					className="text-3xl font-bold">
					Home
				</Link>
				<Link
					to={"/"}
					className="text-3xl font-bold">
					Login
				</Link>
				<Link
					to={"/personal-profile"}
					className="text-3xl font-bold">
					Personal Profile
				</Link>
				<Link
					to={"/drone-creation-request"}
					className="text-3xl font-bold">
					Drone
				</Link>
				<Link
					to={"/supplier-list"}
					className="text-3xl font-bold">
					Supplier List
				</Link>
			</Flex>
		</>
	);
};
