import { Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";

import Input from "../../components/Input";
import type { Drone } from "../../types/drone";
import type { Goods } from "../../types/order";
import type { SelectedDrones } from "./DroneCreationRequest";

interface DroneColumnsProps {
	selectedDrones: Goods[];
	setSelectedDrones: React.Dispatch<React.SetStateAction<SelectedDrones[]>>;
}

export const getDroneColumns = ({
	selectedDrones,
	setSelectedDrones
}: DroneColumnsProps): ColumnsType<Drone> => {
	const changeDronesAmount = (droneId: number, value: number) => {
		setSelectedDrones((prev) => {
			const selectedDroneIdx = prev.findIndex(({ drone }) => drone === droneId);
			if (selectedDroneIdx === -1)
				return [...prev, { drone: droneId, count: value, selected: false }];
			else {
				prev[selectedDroneIdx].count = value;
				return [...prev];
			}
		});
	};

	const setSelectedState = (droneId: number, isSelected: boolean) => {
		setSelectedDrones((prev) => {
			const selectedDroneIdx = prev.findIndex(({ drone }) => drone === droneId);
			if (selectedDroneIdx === -1)
				return [
					...prev,
					{ drone: droneId, selected: isSelected, count: prev[selectedDroneIdx]?.count || 0 }
				];
			else {
				prev[selectedDroneIdx].selected = isSelected;
				return [...prev];
			}
		});
	};

	return [
		{
			title: "Модель",
			dataIndex: "name",
			key: "name"
		},
		{
			title: "Кількість",
			dataIndex: "count",
			key: "count",
			render: (_, { id }) => (
				<Input
					type="primary"
					onChange={({ target }) => {
						changeDronesAmount(id, +target.value);
					}}
				/>
			)
		},
		{
			title: "",
			dataIndex: "actionButton",
			key: "actionButton",
			render: (_, { id }) => (
				<Checkbox
					value={selectedDrones.findIndex(({ drone }) => drone === id) !== -1}
					onChange={({ target }) => {
						setSelectedState(id, target.checked);
					}}>
					Замовити
				</Checkbox>
			)
		}
	];
};
