import { Flex, List, Row } from "antd";
import React from "react";

import Section from "../../../components/Section";
import { data } from "./mockData";

export const HowltWorks: React.FC = (): JSX.Element => {
	return (
		<Section
			className="-mt-[8%] "
			overlay="/images/home/howItWorks-overlay.png">
			<Flex
				vertical
				justify="center"
				className="z-10 h-full"
				gap={60}>
				<Flex
					vertical
					className="items-center text-center"
					gap={16}>
					<img
						src="/images/home/howItWorks-union.png"
						alt="union"
					/>
					<div className="mt-4 flex justify-items-center">
						<h2 className="m-0 font-russo text-6xl uppercase text-primary">
							Як це <span className="font-russo text-6xl uppercase text-white">працює?</span>
						</h2>
					</div>
					<p className="m-0 w-[450px] font-yanone text-3xl uppercase text-primary">
						Кожен може знайти свою роль та долучитись до проекту
					</p>
				</Flex>
				<Row className="w-full justify-around">
					{data.map(({ key, title, list }) => (
						<Flex
							vertical
							gap={10}>
							<Flex
								align="flex-start"
								justify="center"
								className="min-h-20"
								key={key}>
								<h3 className="my-0 mr-5 font-russo text-6xl text-secondary">{`0${key}`}</h3>
								<p className="m-0 mt-1 w-[120px] self-start font-yanone text-3xl uppercase text-white">
									{title}
								</p>
							</Flex>
							<List
								className="w-[250px]"
								size="small"
								dataSource={list}
								renderItem={(infoItem) => <List.Item>{`• ${infoItem}`}</List.Item>}
							/>
						</Flex>
					))}
				</Row>
			</Flex>
		</Section>
	);
};
