import { Flex } from "antd";
import React from "react";

import Table from "../../components/Table";
import { UserRoles } from "../../constants/userRoles";
import { useGetProfileQuery } from "../../services/user/api";
import type { Consumer, Craftsman, UserType } from "../../types/user";
import UserCard from "./UserCard/UserCard";
import { getProfileColumns } from "./profileColums";

export const PersonalProfile: React.FC = (): JSX.Element => {
	const { data: profile } = useGetProfileQuery();

	if (!profile) return <></>;

	const getOrders = () => {
		const currentUser = profile[profile.role];
		return Object.keys(currentUser as UserType).includes("orders")
			? (currentUser as Consumer | Craftsman).orders
			: [];
	};

	return (
		<Flex
			vertical
			justify="space-around"
			align="center"
			className="pt-30">
			<UserCard />
			{/* TODO: Remove .toString() when userRole will be manipulated via the app*/}
			{[UserRoles.CRAFTSMAN, UserRoles.CONSUMER].includes(profile.role) && (
				<Flex
					vertical
					className="w-3/4 pb-20">
					<h3 className="pt-20 ">Дрони в роботі</h3>
					<Table
						columns={getProfileColumns(profile.role)}
						dataSource={getOrders().map((order) => ({
							...order,
							name: order.drone.name,
							id: order.slugId
						}))}
					/>
				</Flex>
			)}
		</Flex>
	);
};
