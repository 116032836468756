const partners = [
	{
		image: "/images/common/logo.png",
		title: "СОНЦЕ РУЇНИ",
		key: "1"
	},
	{
		image: "/images/common/logo.png",
		title: "СОНЦЕ РУЇНИ",
		key: "2"
	},
	{
		image: "/images/common/logo.png",
		title: "СОНЦЕ РУЇНИ",
		key: "3"
	},
	{
		image: "/images/common/logo.png",
		title: "СОНЦЕ РУЇНИ",
		key: "4"
	},
	{
		image: "/images/common/logo.png",
		title: "СОНЦЕ РУЇНИ",
		key: "5"
	}
];

export default partners;
