import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface Slide {
	image: string;
	title: string;
}

interface Props {
	partners: Slide[];
}

export const Slider = ({ partners }: Props) => {
	return (
		<Swiper
			slidesPerView={4}
			spaceBetween={1}
			watchOverflow
			centeredSlidesBounds={true}
			centeredSlides={true}
			centerInsufficientSlides={true}
			loop={true}
			navigation={true}
			modules={[Navigation]}
			className="mySwiper h-fit w-[80%] ">
			{partners.map((partner) => (
				<SwiperSlide
					key={partner.image}
					className="flex h-fit flex-col items-center justify-between gap-2">
					<img
						src={partner.image}
						alt={partner.title}
						className="h-48 w-48 object-contain"
					/>
					<p className="m-0 font-yanone text-xl font-normal uppercase tracking-wide text-white">
						{partner.title}
					</p>
				</SwiperSlide>
			))}
		</Swiper>
	);
};
