import { Flex } from "antd";
import parse from "html-react-parser";
import React from "react";

import Section from "../../../components/Section";
import { data } from "./dataConfig";

export const HowToBecomeSupplier: React.FC = (): JSX.Element => {
	return (
		<Section className="h-full bg-dark">
			<img
				src="/images/supplier/fpv-drone-bg-l.png"
				alt="fpv_drone"
				className="absolute bottom-0 left-0"
			/>
			<img
				src="/images/supplier/fpv-drone-bg-r.png"
				alt="fpv_drone"
				className="absolute right-0 top-[33px]"
			/>
			<Flex vertical>
				<h1 className="m-0 mb-[20px] text-[64px] font-normal leading-[77.12px] text-secondary">
					Як стати <span className="text-white">постачальником?</span>
				</h1>
				<p className="m-0 mb-[20px] w-[65%] text-[24px] font-normal leading-7 text-text">
					Щоб стати <span className="text-white">постачальником запчастин</span> для FPV дронів на
					нашій платформі, слідуйте цим крокам, щоб ваша заявка була <span>успішно розглянута</span>
					:
				</p>
			</Flex>
			<Flex
				vertical
				gap="20px"
				align="center">
				{data.map(({ number, title, description }) => (
					<Flex
						key={number}
						gap="20px"
						className="border-1 relative w-[800px] border-solid border-secondary p-[23px]">
						<img
							className="absolute left-0 top-0 z-10"
							src="/images/supplier/triangels-l.png"
						/>
						<img
							className="absolute bottom-0 right-0 z-10"
							src="/images/supplier/triangels-r.png"
						/>
						<h2 className="z-20 m-0 text-[72px] leading-[4rem] text-white">{number}</h2>
						<h3 className="z-20 m-0 text-[24px] leading-7 text-primary ">{title}</h3>
						<Flex
							vertical
							justify="start">
							<p className="z-20 m-0 block w-[398px] font-yanone text-[20px] leading-[19.84px] tracking-[0.02em] text-text">
								{parse(description)}
							</p>
						</Flex>
					</Flex>
				))}
			</Flex>
		</Section>
	);
};
