import {
	type BaseQueryApi,
	type FetchArgs,
	createApi,
	fetchBaseQuery
} from "@reduxjs/toolkit/query/react";

import { BASE_URL } from "../constants/api.ts";
import { type RootState } from "../store/store.tsx";

export const createBaseQuery = (args: string | FetchArgs, api: BaseQueryApi) => {
	const { accessToken } = (api.getState() as RootState).user;
	return fetchBaseQuery({
		baseUrl: BASE_URL,
		prepareHeaders: (headers) => {
			headers.set("Authorization", `Bearer ${accessToken}`);
			return headers;
		}
	})(args, api, {});
};

export const rtkApi = createApi({
	reducerPath: "api",
	baseQuery: createBaseQuery,
	endpoints: () => ({})
});
