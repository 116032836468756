export const data: {
	number: string;
	title: string;
	description: string;
}[] = [
	{
		number: "01",
		title: "ЗАПОВНІТЬ ФОРМУ",
		description: `Для початку, необхідно <span className="text-white">заповнити форму на сайті</span>. Надайте нам наступну інформацію: 
		 <ul className="list-decimal m-0 block text-white pl-6 font-yanone text-[20px] leading-[19.84px] ">
		 <li>Ім'я*</li>
		 <li>Тип особи (фізична чи юридична)*</li>
		 <li>Електронна пошта*</li>
		 <li>Телефон*</li>
		 <li>Посилання на ваш магазин або каталог запчастин*</li>
		 </ul>
		 `
	},
	{
		number: "02",
		title: "ПЕРЕГЛЯД ЗАЯВКИ",
		description: `Після надходження
			  <span className="text-white"> вашої заявки</span>
		 , наша команда проведе її детальний перегляд. Ми оцінимо ваші 
			  <span className="text-white"> пропозиції та потенціал</span>
			  для співпраці на платформі.`
	},
	{
		number: "03",
		title: "ДОДАВАННЯ ДО ПЕРЕЛІКУ",
		description: `У разі позитивного рішення, ви отримаєте
			  <span className="text-white"> статус постальника</span>
			  і буде додані до переліку постачальників на нашій платформі. Це дозволить інженерам та іншим користувачам платформи
			  <span className="text-white">легко знайти ваші пропозиції</span>
			  та зв'язатися з вами для <span className="text-white">замовлення комплектуючих</span>.
		 `
	}
];
