import { Input, type InputProps } from "antd";

export const InputComponent = (props: InputProps) => {
	return (
		<Input
			{...props}
			className="border-2 font-yanone"
		/>
	);
};
