import { Button, Flex } from "antd";
import { useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";

import Section from "../../../components/Section";
import { UserRoles } from "../../../constants/userRoles";
import { Routes } from "../../../router/routes";
import { useLoginMutation } from "../../../services/user/api";

const data = [
	{ id: 1, title: "Замовник дронів", role: UserRoles.CONSUMER },
	{ id: 2, title: "Виробник дронів", role: UserRoles.CRAFTSMAN },
	{ id: 3, title: "Інвестор", role: UserRoles.INVESTOR }
];

export const JoinToProject: React.FC = (): JSX.Element => {
	const [selectedRole, setSelectedRole] = useState<UserRoles | null>(null);
	const [login] = useLoginMutation();
	const navigate = useNavigate();

	const handleRoleSelection = (role: UserRoles) => {
		setSelectedRole(role);
	};

	const handleLogin = async () => {
		if (selectedRole) {
			//TODO: Remove this line when have Dia auth
			const user = {
				email: `${selectedRole.toLowerCase()}@gmail.com`,
				password: selectedRole.toLowerCase()
			};
			await login(user);
			navigate(Routes.PERSONAL_PROFILE);
		}
	};

	return (
		<Section className="-mt-[8%]">
			<Flex
				align="flex-start"
				justify="space-between">
				<Flex className="h-[600px]  w-full bg-[url('/public/images/home/join-droneBg.png')] bg-cover">
					<Flex
						vertical
						className="w-[50%] items-center">
						<img
							src="/images/home/howItWorks-union.png"
							alt="union"
							className="w-[400px]"
						/>
						<p className="m-0 mt-12 flex w-[550px] flex-col font-russo text-7xl uppercase text-primary">
							Приєднатися
							<span className="m-0 text-7xl uppercase text-white ">до проєкту</span>
						</p>
					</Flex>
					<Flex
						vertical
						className="z-1 h-[830px] w-[50%] items-center">
						<Flex
							vertical
							className="mt-[60px] h-[600px] w-[600px] bg-[url('/public/images/home/join-block.png')] bg-cover">
							<Flex
								vertical
								className="mt-[100px] items-center">
								<Flex vertical>
									{data.map((item) => (
										<Button
											onClick={() => {
												handleRoleSelection(item.role);
											}}
											style={{
												backgroundColor: selectedRole === item.role ? "white" : "transparent",
												color: selectedRole === item.role ? "black" : "white"
											}}
											key={item.id}
											className="mt-6 h-[50px] w-[350px] rounded-none border-2 p-3 font-yanone text-xl uppercase">
											{item.title}
										</Button>
									))}
								</Flex>
								<Button
									onClick={handleLogin}
									className="relative mt-16 flex h-[50px] w-[350px] items-center justify-evenly rounded-none border-2 border-secondary bg-secondary  p-3 font-yanone text-xl uppercase text-white">
									<img
										src="/images/home/join-diiaLogo.png"
										alt="diia-icon"
										className="abosute left-0 top-0 h-[35px] w-[35px]"
									/>
									<p>Вхід через застосунок дія</p>
								</Button>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Section>
	);
};
