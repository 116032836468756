import { Button, Image, Layout, Menu } from "antd";
import type { MenuProps } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Routes } from "../../router/routes";

const items: MenuProps["items"] = [
	{
		label: "ІНСТРУКЦІЯ",
		key: "#1"
	},
	{
		label: "ЗВІТИ",
		key: "#2"
	},
	{
		label: "ШКОЛА",
		key: "#3"
	},
	{
		label: "ПОСТАЧАЛЬНИКАМ ЗАПЧАСТИН",
		key: Routes.SUPPLIER
	}
];
const { Header } = Layout;
export const HeaderComponent = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [currentKey, setCurrentKey] = useState<string>(pathname);

	const onClick: MenuProps["onClick"] = ({ key }) => {
		setCurrentKey(key);
		navigate(key);
	};
	return (
		<Header className="flex h-16 items-center justify-center bg-dark px-20">
			<Image
				className="w-[140px] cursor-pointer "
				onClick={() => {
					navigate(Routes.HOME);
				}}
				preview={false}
				src="/images/common/logosvg.svg"
				alt="Logo"
			/>
			<Menu
				className="flex grow justify-center bg-dark font-yanone text-lg font-medium uppercase tracking-wide "
				mode="horizontal"
				selectedKeys={[currentKey]}
				onClick={onClick}
				items={items}
			/>
			<Button
				className=" rounded-none border-2 font-yanone text-lg font-semibold uppercase tracking-wide"
				onClick={() => {
					navigate(Routes.LOGIN);
				}}
				size="large">
				Приєднатися до проєкту
			</Button>
		</Header>
	);
};
